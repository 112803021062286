<template>
  <CRow>
    <div class="row align-items-center">
      <!-- <label class="row align-items-center" style="text-align: center; font-size: x-large;"> 
        Remaining Debit:&nbsp; Rp. {{ RemainingDebit }} | &nbsp;
      </label> -->
      <span>Remaining Debit:&nbsp; Rp. {{ RemainingDebit }}</span>
    </div>
    &nbsp;
    &nbsp;
    &nbsp;
    &nbsp;
    &nbsp;
    &nbsp;
    <div class="row align-items-center center" style="border-left:1px solid #000;height:auto"></div>
    &nbsp;
    &nbsp;
    &nbsp;
    <div>
      <CDropdown
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavLink>
            <div class="row align-items-center">
              <div class="col-6" style="color:black;">
                {{ Name }}
              </div>
              &nbsp;&nbsp;
              <div class="c-avatar">
                <img :src="require('../../public/img/avatars/user.jpg')" class="c-avatar-img "
                />
              </div>
            </div>          
          </CHeaderNavLink>
        </template>
        <CDropdownHeader tag="div" class="text-center" color="light">
          <strong>{{Role}}</strong>
        </CDropdownHeader>
        <!-- <CDropdownHeader>
          <strong><h4> Remaining Deposit:&nbsp;  Rp. 100000 </h4></strong>
        </CDropdownHeader> -->
        <CDropdownItem @click="ChangePassword()">
          <CIcon name="cil-lock-locked" /> Change Password
        </CDropdownItem>
        <CDropdownItem @click="Logout()">
          <CIcon name="cil-account-logout" /> Logout
        </CDropdownItem>
      </CDropdown>
      <change-password-form ref="changepasswordform" />
    </div>
  </CRow>
</template>

<script>
import changePasswordForm from '../Areas/Core/Account/Component/ChangePasswordForm.vue';

export default {
  name: 'TheHeaderDropdownAccnt',
  components:{
    'change-password-form': changePasswordForm
  },
  data () {
    return { 
      itemsCount: 42,
      Name: this.$cookies.get('Name'),
      Role: this.$cookies.get('RoleName'),
      RemainingDebit: kendo.toString(this.$cookies.get('CurrentDebit'), 'n'),
    }
  },
  methods:{
    Logout(){
      window.sessionStorage.removeItem('ModuleList')

      this.$cookies.remove('Token')
      this.$cookies.remove('Name')
      this.$cookies.remove('UserID')
      this.$cookies.remove('RoleName')
      this.$cookies.remove('RoleID')
      this.$cookies.remove('ContactID')
      this.$cookies.remove('ContactName')
      this.$cookies.remove('TokenExpireTime')

      this.$router.push({ path: '/login' });
    },
    ChangePassword(){
      this.$refs.changepasswordform.changePasswordClick();
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>