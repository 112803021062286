<template>
  <CFooter :fixed="false">
    <div>
      &copy; {{new Date().getFullYear()}}
      <a href="http://westpex.co.id/" target="_blank">Westpex </a>
      <span>| All Rights Reserved</span>
    </div>
    <!-- <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
