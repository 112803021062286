<template>
    <div>
        <div class="modal fade" id="changePasswordModal" tabindex="-1" aria-labelledby="changePasswordModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="changePasswordModalLabel">Change Password</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Current Password</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="CurrentPassword" type="password" v-model="CurrentPassword" class="font-weight-bold" />
                                    <label id="errorCurrentPassword" class="form-error" style="display: none; color: red;"></label>
                                </CCol>   
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">New Password</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="NewPassword" type="password" v-model="NewPassword" class="font-weight-bold" />
                                    <label id="errorNewPassword" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">New Confirm Password</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="NewConfirmPassword" type="password" v-model="NewConfirmPassword" class="font-weight-bold" />
                                    <label id="errorNewConfirmPassword" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>          
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import loginServices from '../Script/LoginServices';
import userServices from '../../User/Script/UserServices';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'ChangePasswordForm',
    components: {
    },
    data: function () {
        return {
            Error: 0,

            CurrentPassword: '',
            NewPassword: '',
            NewConfirmPassword: '',
            
            Username: '',
            UserId: '',
            MyName: '',
            RoleId: '',
            ContactId: '',
            Status: '',
        }
    },
    mounted(){
    },
    methods: {
        async changePasswordClick(){
            $(".form-error").css("display", "none");
            this.CurrentPassword = '';
            this.NewPassword = '';
            this.NewConfirmPassword = '';

            var userData = await loginServices.getUserQuery(window.$cookies.get('UserID'));
            
            this.Username = userData.username;
            this.UserId = userData.userid;
            this.MyName = userData.myname;
            this.RoleId = userData.roleid;
            this.ContactId= userData.contact_id;
            this.Status= userData.active_flag;

            window.$('#changePasswordModal').appendTo("body").modal('show');
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.NewPassword == ''){
                this.errorShow('errorNewPassword');
            }
            if(this.NewConfirmPassword == ''){
                this.errorShow('errorNewConfirmPassword');
            }
            if(this.CurrentPassword == ''){
                this.errorShow('errorCurrentPassword');
            }
            if(this.NewConfirmPassword != this.NewPassword){
                this.$swal("Error", "The new confirm password is not correct", "error");
                this.Error++;
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                this.$loading(true);

                this.$apollo.mutate({
                    mutation: loginServices.changePasswordQuery(),
                    variables:{
                        username: this.Username,
                        password: this.CurrentPassword
                    }
                }).then(res => {
                    const newUserData = {
                        username: this.Username,
                        mypwd: this.NewPassword,
                        myname: this.MyName,
                        contact_id: parseInt(this.ContactId),
                        roleid: parseInt(this.RoleId),
                        active_flag: this.Status
                    };

                    const variables = {
                        id : this.UserId.toString(),
                        data : newUserData
                    };

                    userServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#changePasswordModal').modal('hide');
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });

                }).catch(error => {
                    this.$loading(false);
                    var errorMessage = globalfunc.ErrorExtractor(error)
                    this.$swal("Error", errorMessage, "error");
                })
            }
        }
    },
}
</script>
