<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :color-scheme="'dark'"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none bg-light border-left-5" to="/">
      <!-- <CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="50" 
        viewBox="0 12 375 134"
      />
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 320 134"
      /> -->
      <CImg
        class="c-sidebar-brand-full"
        :src="Logo"
        :width="150"
        :height="75" 
        size="custom-size" 
        viewBox="0 0 556 134"
      />
      <CImg
        class="c-sidebar-brand-minimized" 
        :src="LogoShrink"
        size="custom-size"
        :width="55"
        :height="55" 
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav';
import { uiSetting } from '../infrastructure/constant/variable';

export default {
  name: 'TheSidebar',
  nav,
  data() {
    return {
      Logo: uiSetting.sidebarImage,
      LogoShrink: uiSetting.sidebarShrinkImage
    }
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
